/*
 * ReportsPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file ReportsPage.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { translate } from '../../../Utils/Translate';
import ReportsTable from './ReportsTable';
import { createReport, deleteReport, fetchAllReport } from '../../../Store/Report';
import {
  IReportStateSelector,
  ISettingsStateSelector,
  IStateSelectors
} from '../../../Interfaces/Selectors';
import { IReport } from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import ReportForm from './ReportForm';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

/**
 * Reports component
 */
const ReportsPage: React.FC<any> = (): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const report: IReportStateSelector = useSelector((state: IStateSelectors) => state.report);
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const [isEditingPage, setEditingPage] = useState<boolean>(false);
  const [tablePayload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'created',
      sortOrder: -1
    },
    searchTerm: ''
  });

  /// Action to trigger files addition process
  const handleAdd = async (): Promise<void> => {
    setEditingPage(true);
  };
  /// Handles Report form submission
  const handleReportSubmit = (payload: IReport) => {
    dispatch(createReport(payload));
  };

  /// Triggers on sort btn click. This sends a request to the backend with the specific column information along with the ASC|DESC indicator
  const onSort = (event: any) => {
    const updatedPayload = {
      skip: tablePayload.skip,
      limit: tablePayload.limit,
      sort: {
        sortField: event.sortField,
        sortOrder: event.sortOrder
      },
      filter: tablePayload.filter,
      searchTerm: tablePayload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(fetchAllReport(updatedPayload));
  };

  const onFilter = (termToFind: string) => {
    Object.assign(tablePayload, { searchTerm: termToFind });
    dispatch(fetchAllReport(tablePayload));
  };

  useEffect(() => {
    if (report.isCreatedSuccess) {
      dispatch(fetchAllReport(tablePayload));
      setEditingPage(false);
    }
  }, [report.isCreatedSuccess]);

  const handlePageUpdate = (event: any) => {
    const updatedPayload = {
      skip: event.first,
      limit: event.rows,
      sort: {
        sortField: tablePayload.sort.sortField,
        sortOrder: tablePayload.sort.sortOrder
      },
      searchTerm: tablePayload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(fetchAllReport(updatedPayload));
  };

  useEffect(() => {
    dispatch(fetchAllReport(tablePayload));
  }, []);

  /// Action to trigger delete Report
  const handleDeleteReport = async (reportUUIDs: string[]): Promise<void> => {
    await asyncErrorHandler(dispatch(deleteReport(reportUUIDs)));
    await asyncErrorHandler(dispatch(fetchAllReport(tablePayload)));
  };

  return (
    <>
      <Row>
        <Col>
          <ReportsTable
            onSort={onSort}
            handleAdd={handleAdd}
            reports={report.reports}
            isLoading={report.isLoading}
            onFilter={onFilter}
            handlePageUpdate={handlePageUpdate}
            tablePayload={tablePayload}
            totalRecords={report.totalRecords}
            handleDeleteReport={handleDeleteReport}
          />
        </Col>
      </Row>
      <DialogWrapper
        className="add-participant-dialog"
        isDialogVisible={isEditingPage}
        headerTitle={translate('client.page.reports.table.reportAnIssue')}
        onHide={() => setEditingPage(false)}>
        <ReportForm
          handleSubmit={handleReportSubmit}
          isLoading={report.isCreatedLoading}
          settings={settings}
        />
      </DialogWrapper>
    </>
  );
};

export default ReportsPage;
