/*
 * Report.ts (AbstractLicensingBackend)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file Report.ts
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IComment,
  IReport,
  IReportFile
} from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import {
  addCommentAPI,
  addEmailOnReportAPI,
  changeStatusAPI,
  createReportAPI,
  deleteEmailOnReportAPI,
  deleteFileOnReportAPI,
  deleteReportAPI,
  downloadFile,
  getAllReport,
  getReportByID,
  getUserReport,
  updateReportAPI,
  uploadFileAPI
} from '../Services/Report';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IImageUploadResponse } from '@abstract/abstractwebcommon-shared/interfaces/license/setting';
import {
  IReducerAction,
  PaginationResponseAction
} from '@abstract/abstractwebcommon-shared/interfaces/store';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const REPORT_FEATURE_KEY = 'report';

/**
 * report Interface
 */
interface IReportState {
  reports: IReport[];
  report: IReport;
  isLoading: boolean /** True if loading and false otherwise. */;
  isDownloadingAttachedFile: boolean /** True if downloading file and false otherwise. */;
  isCreatedLoading: boolean /** True if loading and false otherwise. */;
  isCreatedSuccess: boolean /** True if created is successful and false otherwise. */;
  participants: IUser[] /** user object */;
  totalRecords: number /** Total records */;
  isDeletedLoading: boolean /** True if loading and false otherwise. */;
  isDeletedSuccess: boolean /** True if deleted is successful and false otherwise. */;
  isReportDeletedLoading: boolean /** True if loading and false otherwise. */;
  isGetOneReportWithError: boolean | null /** Defines if the get one report returned error. */;
}

/**
 * Report Intial state
 */
const INITIAL_STATE: IReportState = {
  reports: null,
  report: null,
  participants: null,
  isLoading: false,
  isCreatedLoading: false,
  isCreatedSuccess: false,
  isDownloadingAttachedFile: false,
  totalRecords: 0,
  isDeletedLoading: false,
  isDeletedSuccess: false,
  isReportDeletedLoading: false,
  isGetOneReportWithError: null
};

export const reportSlice = createSlice({
  name: REPORT_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    reset: (state: IReportState) => {
      state.report = null;
    },
    addReportRequest(state: IReportState) {
      state.isCreatedLoading = true;
      state.isCreatedSuccess = false;
    },
    addReportSuccess(state: IReportState) {
      state.isCreatedLoading = false;
      state.isCreatedSuccess = true;
    },
    addReportFailure(state: IReportState) {
      state.isCreatedLoading = false;
    },
    deleteFileRequest(state: IReportState) {
      state.isDeletedLoading = true;
      state.isDeletedSuccess = false;
    },
    deleteFileSuccess(state: IReportState) {
      state.isDeletedLoading = false;
      state.isDeletedSuccess = true;
    },
    deleteFileFailure(state: IReportState) {
      state.isDeletedLoading = false;
    },
    getReportRequest(state: IReportState) {
      state.isLoading = true;
    },
    getReportsSuccess(state: IReportState, action: PaginationResponseAction<IReport>) {
      state.isLoading = false;
      state.reports = action.payload.records;
      state.totalRecords = action.payload.totalRecords;
    },
    getReportSuccess(state: IReportState, action: IReducerAction<IReport>) {
      state.isLoading = false;
      state.report = action.payload;
      state.isCreatedSuccess = false;
    },
    getReportFailure(state: IReportState, action: any) {
      state.isLoading = false;
      state.report = undefined;

      // If status is '401' we should just send the user to the login page.
      if (action.payload?.status !== 401) {
        state.isGetOneReportWithError = true;
      }
    },
    getParticipantSuccess(state: IReportState, action: IReducerAction<IUser[]>) {
      state.isLoading = false;
      state.participants = action.payload;
    },
    getDownloadRequest(state: IReportState) {
      state.isDownloadingAttachedFile = true;
    },
    getDownloadFailure(state: IReportState) {
      state.isDownloadingAttachedFile = false;
    },
    getDownloadSuccess(state: IReportState) {
      state.isDownloadingAttachedFile = false;
    },
    deleteReportRequest(state: IReportState) {
      state.isReportDeletedLoading = true;
    },
    deleteReportSuccess(state: IReportState) {
      state.isReportDeletedLoading = false;
    },
    deleteReportFailure(state: IReportState) {
      state.isReportDeletedLoading = false;
    }
  },
  extraReducers: {}
});

export const reportReducer = reportSlice.reducer;
export const reportActions = reportSlice.actions;

/**
 * Create report.
 * @param payload
 */
export const createReport = createAsyncThunk('report/create', async (payload: any, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { addReportRequest, addReportFailure, addReportSuccess } = reportActions;

  try {
    dispatch(addReportRequest());
    if (payload.files) {
      const responses: IAPIEntityResponse<IImageUploadResponse[]> = await asyncErrorHandler(
        Promise.all(payload.files.map(async (file) => uploadFileAPI(file)))
      );
      if (responses[0].error) {
        dispatch(addReportFailure());
        handleError({ message: responses[0].error.message });
        return;
      }

      payload.files = responses.map((response) => response.data);
    }

    const result: IAPIEntityResponse<IReport> = await asyncErrorHandler(createReportAPI(payload));
    if (result.error) {
      dispatch(addReportFailure());
      handleError({ message: result.error.message });
    } else {
      showSuccessToast(result.message);
      dispatch(addReportSuccess());
    }
  } catch (error: any) {
    dispatch(addReportFailure());
    handleError({ message: error.message });
  }
});

/**
 * Get user reports.
 */
export const fetchUserReport = createAsyncThunk(
  'report/getUser',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getReportRequest, getReportFailure, getReportsSuccess } = reportActions;

    try {
      dispatch(getReportRequest());
      const result: IAPIEntityResponse<IReport[]> = await asyncErrorHandler(getUserReport(payload));
      if (result.error) {
        dispatch(getReportFailure(result as any));
        handleError({ message: result.error.message });
      } else {
        dispatch(getReportsSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getReportFailure(error));
      handleError({ message: error.message });
    }
  }
);

/**
 * Get all reports.
 */
export const fetchAllReport = createAsyncThunk('report/all', async (payload: any, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { getReportRequest, getReportFailure, getReportsSuccess } = reportActions;

  try {
    dispatch(getReportRequest());
    const result: IAPIEntityResponse<IReport[]> = await asyncErrorHandler(getAllReport(payload));
    if (result.error) {
      dispatch(getReportFailure(result as any));
      handleError({ message: result.error.message });
    } else {
      dispatch(getReportsSuccess(result.data));
    }
  } catch (error: any) {
    dispatch(getReportFailure(error));
    handleError({ message: error.message });
  }
});

/**
 * Get report by id.
 */
export const fetchReport = createAsyncThunk('report/one', async (id: string, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { getReportRequest, getReportFailure, getReportSuccess } = reportActions;

  try {
    dispatch(getReportRequest());
    const result: IAPIEntityResponse<IReport> = await asyncErrorHandler(getReportByID(id));
    if (result.error) {
      dispatch(getReportFailure(result as any));
      handleError({ message: result.error.message });
    } else {
      dispatch(getReportSuccess(result.data));
    }
  } catch (error: any) {
    dispatch(getReportFailure(error));
    handleError({ message: error.message });
  }
});

/**
 * Add comment.
 * @param payload
 */
export const addComment = createAsyncThunk('message/add', async (payload: any, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { addReportRequest, addReportFailure, addReportSuccess } = reportActions;

  try {
    dispatch(addReportRequest());
    if (payload.report.files) {
      const responses: IAPIEntityResponse<IImageUploadResponse[]> = await asyncErrorHandler(
        Promise.all(
          payload.report.files.map(async (file) => uploadFileAPI(file, payload.report.reportUUID))
        )
      );

      if (responses[0].error) {
        dispatch(addReportFailure());
        handleError({ message: responses[0].error.message });
        return;
      }
      payload.report.files = responses.map((response) => response.data);
    }

    const result: IAPIEntityResponse<IComment> = await asyncErrorHandler(addCommentAPI(payload));
    if (result.error) {
      dispatch(addReportFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(addReportSuccess());
      showSuccessToast(result.message);
    }
  } catch (error: any) {
    dispatch(addReportFailure());
    handleError({ message: error.message });
  }
});

/**
 * Change report status.
 * @param payload IReport
 */
export const changeReportStatus = createAsyncThunk(
  'report/reportUUID/status',
  async (payload: IReport, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { addReportRequest, addReportFailure, addReportSuccess } = reportActions;
    try {
      dispatch(addReportRequest());
      const result: IAPIEntityResponse<IReport> = await asyncErrorHandler(changeStatusAPI(payload));
      if (result.error) {
        dispatch(addReportFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(addReportSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(addReportFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * update report.
 * @param payload IReport
 */
export const addEmailOnReport = createAsyncThunk(
  'report/reportUUID/addEmail',
  async (payload: IReport, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { addReportRequest, addReportFailure, addReportSuccess } = reportActions;
    try {
      dispatch(addReportRequest());
      const result: IAPIEntityResponse<IReport> = await asyncErrorHandler(
        addEmailOnReportAPI(payload)
      );
      if (result.error) {
        dispatch(addReportFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(addReportSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(addReportFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * update report.
 * @param payload IReport
 */
export const updateReport = createAsyncThunk(
  'report/reportUUID/update',
  async (payload: IReport, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { addReportRequest, addReportFailure, addReportSuccess } = reportActions;
    try {
      dispatch(addReportRequest());
      const result: IAPIEntityResponse<IReport> = await asyncErrorHandler(updateReportAPI(payload));
      if (result.error) {
        dispatch(addReportFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(addReportSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(addReportFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * update report.
 * @param payload IReport
 */
export const deleteEmailOnReport = createAsyncThunk(
  'report/reportUUID/update',
  async (payload: IReport, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { addReportRequest, addReportFailure, addReportSuccess } = reportActions;
    try {
      dispatch(addReportRequest());
      const result: IAPIEntityResponse<IReport> = await asyncErrorHandler(
        deleteEmailOnReportAPI(payload)
      );
      if (result.error) {
        dispatch(addReportFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(addReportSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(addReportFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Download file.
 */
export const downloadFileReport = createAsyncThunk(
  'report/download',
  async (payload: Record<string, string>, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getDownloadRequest, getDownloadFailure, getDownloadSuccess } = reportActions;

    try {
      dispatch(getDownloadRequest());
      const response: IAPIEntityResponse<{ signedDownloadURL: string }> = await asyncErrorHandler(
        downloadFile(payload.fileUUID, payload.reportUUID)
      );

      if (response.error) {
        dispatch(getDownloadFailure());
        handleError({ message: response.error.message });
        return;
      }
      const signedDownloadURL: string = response?.data?.signedDownloadURL;
      if (signedDownloadURL) {
        window.location.href = signedDownloadURL;
      }
      dispatch(getDownloadSuccess());
      showSuccessToast(response.message);
    } catch (error: any) {
      dispatch(getDownloadFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Delete file.
 */
export const deleteFileReport = createAsyncThunk(
  'report/download',
  async (payload: IReportFile, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { deleteFileRequest, deleteFileFailure, deleteFileSuccess } = reportActions;
    try {
      dispatch(deleteFileRequest());
      const result: IAPIEntityResponse<IReport> = await asyncErrorHandler(
        deleteFileOnReportAPI(payload)
      );
      if (result.error) {
        dispatch(deleteFileFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(deleteFileSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(deleteFileFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Delete report(s) Action.
 * @param payload
 */
export const deleteReport = createAsyncThunk(
  'report/delete',
  async (payload: string[], thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { deleteReportRequest, deleteReportSuccess, deleteReportFailure } = reportActions;

    try {
      dispatch(deleteReportRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(deleteReportAPI(payload));
      if (result.error) {
        dispatch(deleteReportFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(deleteReportSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(deleteReportFailure());
      handleError({ message: error.message });
    }
  }
);
