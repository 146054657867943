/*
 * Comments.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file Comments.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from 'react';
import Avatar from '@abstract/abstractwebcommon-client/Avatar';
import { translate } from '../../../Utils/Translate';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { timeSince } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { IComment } from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import GalleriaWrapper from '@abstract/abstractwebcommon-client/Galleria/GalleriaWrapper';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

/**
 * @interface IReportsTableProperties
 */
interface ICommentsProperties {
  comments: IComment[] | [] /**< report comments. */;
  participants: IUser[] /**< user array of object */;
  handleDownloadFile: (
    fileUUID: string,
    reportUUID: string
  ) => void /**< Triggers download file. */;
}
/**
 * Comments component
 */
const Comments = (properties: ICommentsProperties): ReactElement => {
  return (
    <>
      {properties.comments &&
        properties.comments.map((comment) => (
          <div
            key={comment.commentUUID}
            className={
              comment.isInternalComment ? 'comment-section comment-internal' : 'comment-section'
            }
            id={comment.commentUUID}>
            <div className="d-flex justify-content-start">
              <Avatar
                imageUrl={comment.imageUrl}
                user={comment}
                isShowTooltipForDeletedUser={false}
              />
              <div className="ml-3 comment-text">
                <div className="d-flex align-items-center flex-wrap">
                  <p className="m-0 mr-2">
                    {comment.username
                      ? `${comment.firstName} ${comment.lastName}`
                      : '[Deleted User]'}
                  </p>
                  <span className="p-0 m-0">
                    {timeSince(new Date(comment.created))}
                    {comment.isInternalComment && (
                      <span className="ml-2 mt-0 font-weight-bold">
                        {translate('client.page.reports.form.internalComment')}
                      </span>
                    )}
                  </span>
                </div>
                <div>
                  <ReactMarkdown
                    className="mt-1 comment-markdown text-break"
                    remarkPlugins={[remarkGfm]}>
                    {comment.comment}
                  </ReactMarkdown>
                  <div className="report-display report-photo-comment ml-1">
                    {comment.files && comment.files.length > 0 && (
                      <GalleriaWrapper
                        report={comment}
                        displayDownloadAction
                        handleDownloadFile={properties.handleDownloadFile}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default Comments;
