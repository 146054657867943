/*
 * Auth.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Auth.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {
  IUserInformationToken,
  UserAuthenticationToken
} from '@abstract/abstractwebcommon-shared/utils/UserAuthenticationToken';
import {
  ActionCreatorWithNonInferrablePayload,
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  AnyAction,
  createAsyncThunk,
  createSlice,
  ThunkDispatch
} from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import { checkIsAdmin, getUserProfile } from '../Services/Auth';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

/**
 * Defines the interface for the 'getProfile' action
 */
interface IGetProfileAction {
  isSupportPresent?: boolean /**< To Check the user has support entitlement licenses or not */;
  isEulaRequired?: boolean /**< To Check the user has Eula entitlement licenses or not */;
  isSLARequired?: boolean /**< To Check the user has SLA entitlement licenses or not */;
  isSublicensePresent?: boolean /**< To Check the user has sublicense entitlement licenses or not */;
  isSupportUser?: boolean /**< Defines whether the user has or not the support role */;
  imageUrl?: string /**< Defines the user profile picture URL */;
}

/**
 * Authentication payload interface
 */
interface IAuthPayload {
  accessToken: string /**< JWT user access token */;
  email: string /**< User email */;
  fullName: string /**< User full name */;
  firstName: string /**< User first name */;
  lastName: string /**< User last name */;
  userUUID: string /**< User uuid */;
  applications: IApplications[] /**< User applications */;
  isAdmin: boolean /**< True if user is admin, false otherwise */;
  username: string /**< Username */;
  applicationUUID: string /**< Application uuid */;
  imageUrl: string /**< user profile image url */;
}

export const AUTH_FEATURE_KEY = 'auth';
export interface IAuthState {
  loginChecking: boolean;
  loginError: any;
  username: string | null;
  fullName: string | null;
  firstName: string;
  lastName: string;
  userUUID: string;
  isAdmin: boolean;
  isAuthenticated: boolean;
  isAuthChecked: boolean;
  passwordIsChanging: boolean;
  accessToken: string | null;
  isReconnecting: boolean;
  imageUrl: string | null;
  isSupportPresent: boolean /**< To Check the user has support entitlement licenses or not */;
  isProfileLoaded: boolean /**> To check profile is loaded or not */;
  isEulaRequired: boolean /**< To Check the user has Eula entitlement licenses or not */;
  isSLARequired: boolean /**< To Check the user has SLA entitlement licenses or not */;
  profilePictureURL: string | null /**< user profile image url from another source */;
  isSublicensePresent: boolean /**< To Check the user has Sublicense entitlement licenses or not */;
  isSupportUser: boolean | null /**< Defines whether the user has or not the support role */;
}

const INITIAL_STATE: IAuthState = {
  loginChecking: false,
  loginError: null,
  username: null,
  fullName: null,
  firstName: null,
  lastName: null,
  userUUID: null,
  isAuthenticated: false,
  isAdmin: false,
  isAuthChecked: false,
  passwordIsChanging: false,
  accessToken: null,
  isReconnecting: false,
  imageUrl: null,
  isSupportPresent: false,
  isProfileLoaded: false,
  isEulaRequired: false,
  isSLARequired: false,
  profilePictureURL: null,
  isSublicensePresent: false,
  isSupportUser: null
};

export const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    loginSuccess(state: IAuthState, action: IReducerAction<IAuthPayload>) {
      const accessToken: string = action.payload.accessToken;
      const email: string = action.payload.email || null;
      const userUUID: string = action.payload.userUUID || null;
      const isAdmin: boolean = action.payload.isAdmin || false;
      const username: string = action.payload.username || null;
      const fullName: string = action.payload.fullName || null;
      const firstName: string = action.payload.firstName || null;
      const lastName: string = action.payload.lastName || null;
      const applicationUUID: string = action.payload.applicationUUID || null;

      LocalStorage.setXAuthToken(accessToken);
      LocalStorage.setXUserUUID(userUUID);
      LocalStorage.setEmail(email);
      LocalStorage.setXApplicationUUID(applicationUUID);
      LocalStorage.setAdmin(JSON.stringify(isAdmin).toLowerCase());
      LocalStorage.setFirstName(firstName);
      LocalStorage.setFullName(fullName);
      LocalStorage.setLastName(lastName);
      LocalStorage.setUserName(username);
      state.username = username;
      state.fullName = fullName;
      state.firstName = firstName;
      state.lastName = lastName;
      state.isAuthenticated = true;
      state.isAdmin = isAdmin;
      state.isAuthChecked = true;
      state.loginChecking = false;
      state.loginError = null;
      state.accessToken = accessToken;
      state.userUUID = userUUID;
    },
    loginFailure(state: IAuthState) {
      state.loginChecking = false;
    },
    logout(state: IAuthState) {
      LocalStorage.removeXAuthToken();
      LocalStorage.removeXApplicationUUID();
      LocalStorage.removeXUserUUID();
      LocalStorage.removeIsAdmin();
      LocalStorage.removeUserName();
      LocalStorage.removeEmail();
      state.isAuthenticated = false;
      state.isAuthChecked = false;
      state.isAdmin = false;
      state.username = null;
      state.fullName = null;
      state.firstName = null;
      state.lastName = null;
      state.accessToken = null;
    },
    reconnectRequest(state: IAuthState) {
      state.isReconnecting = true;
    },
    reconnect(state: IAuthState) {
      const fullName: any = LocalStorage.getFullName();
      const firstName: any = LocalStorage.getFirstName();
      const lastName: any = LocalStorage.getLastName();
      const username: any = LocalStorage.getUserName();
      const isAdmin: any = LocalStorage.getAdmin();
      if (LocalStorage.getXAuthToken() && username) {
        state.username = username;
        state.fullName = fullName;
        state.firstName = firstName;
        state.lastName = lastName;
        state.isAuthenticated = true;
        state.isAdmin = isAdmin;
        state.isAuthChecked = true;
        state.userUUID = LocalStorage.getXUserUUID();
      }
      state.isReconnecting = false;
    },
    getProfile(state: IAuthState, action: IReducerAction<IGetProfileAction>) {
      state.imageUrl = action.payload.imageUrl || null;
      state.isSupportPresent = action.payload.isSupportPresent;
      state.isProfileLoaded = true;
      state.isEulaRequired = action.payload.isEulaRequired;
      state.isSLARequired = action.payload.isSLARequired;
      state.profilePictureURL = action.payload.imageUrl;
      state.isSublicensePresent = action.payload.isSublicensePresent;
      state.isSupportUser = action.payload.isSupportUser;
    },
    updateEulaEntitlementState: (state: IAuthState) => updateEulaEntitlementStateAction(state),
    updateSLAEntitlementState: (state: IAuthState) => updateSLAEntitlementStateAction(state)
  },
  extraReducers: {}
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;

export const logoutAction = createAsyncThunk('auth/signout', async (payload: void, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { logout } = authActions;
  dispatch(logout());
});

export const reconnectAction = createAsyncThunk('auth/signin', async (payload: void, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { reconnect, reconnectRequest } = authActions;
  dispatch(reconnectRequest());
  dispatch(reconnect());
});

/**
 * To update eula entitlement state
 * @param state
 */
const updateEulaEntitlementStateAction = (state: IAuthState) => {
  state.isEulaRequired = false;
};

/**
 * To update SLA entitlement state
 * @param state
 */
const updateSLAEntitlementStateAction = (state: IAuthState) => {
  state.isSLARequired = false;
};

/**
 * sign in auths Action.
 * @param payload
 */
export const signUserIntoApplicationAction = createAsyncThunk(
  'auth/signin',
  async (payload: { token: string; adminRoleUUID: string; applicationUUID: string }, thunkAPI) => {
    const dispatch: ThunkDispatch<unknown, unknown, AnyAction> = thunkAPI.dispatch;
    const token: string = payload.token;
    const adminRoleUUID: string = payload.adminRoleUUID;
    const applicationUUID: string = payload.applicationUUID;
    const loginFailure:
      | ActionCreatorWithNonInferrablePayload<string>
      | ActionCreatorWithoutPayload<string> = authActions.loginFailure;
    const loginSuccess:
      | ActionCreatorWithNonInferrablePayload<string>
      | ActionCreatorWithoutPayload<string>
      | ActionCreatorWithPayload<IAuthPayload> = authActions.loginSuccess;
    try {
      const decodedToken: IUserInformationToken = new UserAuthenticationToken(
        jwtDecode(token)
      ).getUserAuthenticationToken();
      const isAdmin: boolean = checkIsAdmin(decodedToken.roles, adminRoleUUID);
      const authPayload: any = {
        accessToken: token,
        email: decodedToken.email,
        fullName: `${decodedToken.firstName} ${decodedToken.lastName}`,
        firstName: decodedToken.firstName,
        lastName: decodedToken.lastName,
        userUUID: decodedToken.userUUID,
        applications: decodedToken.applications,
        isAdmin,
        username: decodedToken.username,
        applicationUUID
      };

      dispatch(loginSuccess(authPayload));
    } catch (exception: any) {
      dispatch(loginFailure(exception.message));
      handleError({ message: exception.message });
    }
  }
);

export const getAuthenticatedUserProfile = createAsyncThunk('auth/profile', async (_, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { getProfile } = authActions;

  const response = await asyncErrorHandler(getUserProfile());

  if (response.status === 200) {
    dispatch(getProfile(response.data));
  } else {
    handleError({ message: 'We are experiencing server issues. Please try again later.' });
  }
});

export const getAuthState = (rootState: any) => rootState[AUTH_FEATURE_KEY];
