/*
 * ReportPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file ReportPage.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { translate } from '../../../Utils/Translate';
import {
  fetchReport,
  addComment,
  updateReport,
  deleteEmailOnReport,
  downloadFileReport,
  deleteFileReport,
  addEmailOnReport,
  reportActions
} from '../../../Store/Report';
import {
  IReportStateSelector,
  IStateSelectors,
  ISettingsStateSelector,
  IAuthStateSelector
} from '../../../Interfaces/Selectors';
import Report from './ReportTable';
import Comments from './Comments';
import CommentForm from './CommentForm';
import {
  IComment,
  IReport,
  IReportFile
} from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import NotFound from '../../NotFound/NotFound';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import UserForm from './UserForm';
import UserList from './UserList';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import ReportPageSkeleton from '@abstract/abstractwebcommon-client/SkeletonLoader/ReportPageSkeleton/ReportPageSkeleton';
import { ReportStatus } from '@abstract/abstractwebcommon-shared/enum/license/report';

/**
 * @interface IReportPageProperties
 */
interface IReportPageProperties {
  reportUUID: string /**< report UUID. */;
}

/**S
 * Report component
 */
const ReportPage: React.FC<any> = (): JSX.Element => {
  const [isUserListOpen, setUserListOpen] = useState<boolean>(false);
  const parameters = useParams<IReportPageProperties>();
  const dispatch: Dispatch<any> = useDispatch();
  const reportState: IReportStateSelector = useSelector((state: IStateSelectors) => state.report);
  const auth: IUser = useSelector((state: IStateSelectors) => state.auth);
  const authState: IAuthStateSelector = useSelector((state: IStateSelectors) => state.auth);
  const [isReportFilesLoading, setReportFilesLoading] =
    useState<boolean>(true); /**< Report files loading state. */
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const report = reportState.report;

  // NOTE: Tickets in the status "Done" can be only editted by admin or support users.
  // NOTE: If the ticket is done and the user is not admin or a support user, We should only allow the ticket attachments download.
  const isReportEditable: boolean =
    report?.status === ReportStatus.done && !(authState.isAdmin || authState.isSupportUser);

  /// Action to trigger user form
  const handleUserListOpen = async (): Promise<void> => {
    setUserListOpen(true);
  };

  /// Handles Report form submission
  const handleReportUpdate = (payload: IReport) => {
    payload.reportUUID = parameters.reportUUID;
    dispatch(updateReport(payload));
  };

  /// Handles Report form submission
  const handleAddEmailOnReport = (payload: IReport) => {
    payload.reportUUID = parameters.reportUUID;
    dispatch(addEmailOnReport(payload));
  };

  /// Handles Report form submission
  const handleReportEmailDelete = (email: string) => {
    const payload: IReport = {};
    payload.reportUUID = parameters.reportUUID;
    payload.emailTo = email;
    dispatch(deleteEmailOnReport(payload));
  };
  /// Handles comment form submission
  const handleCommentSubmit = (payload: IComment) => {
    payload.report.reportUUID = parameters.reportUUID;
    dispatch(addComment(payload));
  };

  /// Triggers download file action.
  const handleDownloadFile = (fileUUID: string, reportUUID: string) => {
    const payload: Record<string, string> = {
      fileUUID,
      reportUUID: reportUUID ?? parameters.reportUUID
    };
    dispatch(downloadFileReport(payload));
  };

  /// Triggers download file action.
  const handleFileDelete = (payload: IReportFile) => {
    dispatch(deleteFileReport(payload));
  };

  // Handle success of copying text to clipboard in Toast.
  const copyToClipboardSuccessHandler = (text?: string) => {
    showSuccessToast(
      translate('I18N.success_messages.copy_success', {
        text: text || `${translate('I18N.success_messages.copy_success')}`
      })
    );
  };

  useEffect(() => {
    dispatch(fetchReport(parameters.reportUUID));

    // Reset report state on unmount
    return () => {
      dispatch(reportActions.reset());
    };
  }, []);

  useEffect(() => {
    if (reportState.isCreatedSuccess) {
      dispatch(fetchReport(parameters.reportUUID));
    }
  }, [reportState.isCreatedSuccess]);

  useEffect(() => {
    if (reportState.isDeletedSuccess) {
      dispatch(fetchReport(parameters.reportUUID));
    }
  }, [reportState.isDeletedSuccess]);

  if (report === null) {
    return <ReportPageSkeleton />;
  }

  if (reportState.isGetOneReportWithError) {
    return <NotFound />;
  }

  /// Note: If all report files are loaded, remove the skeleton
  if (!isReportFilesLoading) {
    const reportSkeleton: HTMLElement = document.getElementById('report-skeleton');
    if (reportSkeleton) {
      reportSkeleton.remove();
    }
    const reportSection: HTMLElement = document.getElementById('report-section');
    if (reportSection) {
      reportSection.classList.remove('invisible');
    }
  }

  return (
    <>
      <div id="report-skeleton">
        <ReportPageSkeleton />
      </div>
      <div id="report-section" className="report-section invisible">
        <Row>
          <Col md={12} className="px-0">
            <Report
              report={report}
              auth={auth}
              isSupportRole={settings && settings.safeSettings.isSupportRole}
              handleUserListOpen={handleUserListOpen}
              copyToClipboardSuccessHandler={copyToClipboardSuccessHandler}
              handleDownloadFile={handleDownloadFile}
              isLoading={!report}
              isDownloadingAttachedFile={reportState.isDownloadingAttachedFile}
              handleFileDelete={handleFileDelete}
              isDeletedLoading={reportState.isDeletedLoading}
              handleReportUpdate={handleReportUpdate}
              settings={settings}
              setReportFilesLoading={setReportFilesLoading}
            />
          </Col>

          {!isReportEditable ? (
            <Col md={12} className="mb-3">
              <CommentForm
                report={report}
                isLoading={!report}
                handleSubmit={handleCommentSubmit}
                auth={auth}
                isCreationSuccessful={reportState.isCreatedSuccess}
                isCreationLoading={reportState.isCreatedLoading}
                isSupportRole={settings && settings.safeSettings.isSupportRole}
                settings={settings}
              />
            </Col>
          ) : (
            <></>
          )}
          <Col lg={12}>
            <Comments
              comments={report.comments}
              participants={report.participants}
              handleDownloadFile={handleDownloadFile}
            />
          </Col>
        </Row>
        <DialogWrapper
          className="add-participant-dialog"
          isDialogVisible={isUserListOpen}
          headerTitle={
            (settings && settings.safeSettings.isSupportRole) || auth.isAdmin
              ? translate('client.page.reports.table.manageUsers')
              : translate('client.page.reports.table.addParticpant')
          }
          onHide={() => setUserListOpen(false)}>
          <UserForm
            handleSubmit={handleAddEmailOnReport}
            isLoading={reportState.isCreatedLoading}
          />
          <UserList
            participants={report.participants}
            user={report.user}
            handleDelete={handleReportEmailDelete}
            auth={auth}
            isSupportRole={settings && settings.safeSettings.isSupportRole}
          />
        </DialogWrapper>
      </div>
    </>
  );
};

export default ReportPage;
